<template>
  <div class="contact">
    <h1 class="title site-title">
      For all forms of contact just shoot a message through twitter which can be
      found below in the footer of this page!
    </h1>
    <button class="btn red_btn" @click="$router.push('/')">Return home</button>
  </div>
</template>

<script src="./contact.ts"></script>
<style src="./contact.scss" lang="scss"></style>
