<template>
  <div class="shop">
    <h1 class="title site-title">
      Coming Soon...
    </h1>
    <button class="btn red_btn" @click="$router.push('/')">Return home</button>
  </div>
</template>

<script src="./shop.ts"></script>
<style src="./shop.scss" lang="scss"></style>
