<template>
  <div class="not-found">
    <h1 class="title site-title">Request Access</h1>
    <h4 class="site-title">Coming soon</h4>
    <button class="btn red_btn" @click="$router.push('/')">Return home</button>
  </div>
</template>

<script src="./request-access.ts"></script>
<style src="./request-access.scss" lang="scss"></style>
