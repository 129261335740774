export default {
  Login: "login",
  SignUp: "signup",
  Home: "home",
  Invisibles: "invisibles",
  NotFound: "not-found",
  Profile: "profile",
  Article: "article",
  Writers: "writers",
  Writer: "writer",
  CreatePost: "create-post",
  Contact: "contact",
  Shop: "shop",
  About: "about",
  RequestAccess: "request-access",
  Admin: "admin",
};
