<template>
  <div class="default-width loading-bar">
    <div id="myProgress">
      <div id="myBar">0%</div>
    </div>
  </div>
</template>

<script src="./loading-bar.ts"></script>
<style src="./loading-bar.scss" lang="scss"></style>
